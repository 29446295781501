<template lang="pug">
  .car-class-settings-card
    .form-row
      .form-row-label
        FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.car_class')")
        span.required {{ $t('company_system.req') }}
      .form-row-field
        AppDropdown(
          allow-empty
          close-on-select
          :disabled="isEdit"
          :class="{ 'is-invalid': priceRow.car_class_id.$error }"
          :is-item-disabled="isCarClassDisabled"
          :value="selectedCarClass"
          :items="carClasses"
          @select="$emit('select-car-class', $event)"
        )
    .form-row
      .form-row-label
        FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.price_list')")
        span.required {{ $t('company_system.req') }}
      .form-row-field.price-list
        .price-list-item.flex-row(v-for="(value, key) in prices")
          .period
            .label
              span {{ extractPriceLabel(key) }}
          .input.flex-row
            AppNumberInput(
              use-delimiter
              :max="999999"
              :invalid="checkPriceError(key)"
              :name="key"
              :value="value"
              @input="updatePrice(key, $event)"
            )
            .currency
              span {{ $t("company_system.currency") }}
    template(v-if="useOptions")
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.cancellation_fee_type')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          AppDropdown(
            allow-empty
            close-on-select
            :class="{ 'is-invalid': priceRow.cancellation_fee_id.$error }"
            :value="selectedCancellationFee"
            :items="cancellationFees"
            @select="selectCancellationFee"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.standard_options')")
        .form-row-field
          AppDropdown(
            multiple
            batch-select
            allow-empty
            :value="selectedStandardOptions"
            :items="standardOptions"
            @select="selectStandardOptions"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.rate_plan.details.car_class_settings.card.selection_options')")
        .form-row-field
          AppDropdown(
            multiple
            batch-select
            allow-empty
            :value="selectedSelectableOptions"
            :items="selectableOptions"
            @select="selectSelectableOptions"
          )
    .form-row.actions
      AppIconButton.clone(
        :disabled="cloneDisabled"
        icon="clone"
        title="company_system.clone"
        @click="$emit('clone')"
      )
      AppIconButton.delete(
        use-app-icon
        icon="trash"
        title="company_system.delete"
        @click="$emit('delete')"
      )
</template>

<script>
  // misc
  import { find, map, get, filter, isNaN, split, isEmpty } from "lodash-es"

  export default {
    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      priceRow: {
        type: Object,
        default: () => {}
      },
      usePriceTable: {
        type: Boolean,
        default: true
      },
      useOptions: {
        type: Boolean,
        default: true
      },
      cloneDisabled: {
        type: Boolean,
        default: false
      },
      carClasses: {
        type: Array,
        default: () => []
      },
      selectedCarClassIds: {
        type: Array,
        default: () => []
      },
      timeSlotType: {
        type: String,
        default: undefined
      },
      cancellationFees: {
        type: Array,
        default: () => []
      },
      standardOptions: {
        type: Array,
        default: () => []
      },
      selectableOptions: {
        type: Array,
        default: () => []
      }
    },

    methods: {
      checkPriceError(priceKey) {
        return this.priceRow.prices[priceKey]?.$error
      },

      isCarClassDisabled({ id }) {
        return this.selectedCarClassIds.includes(id)
      },

      extractPriceLabel(key) {
        const rightPart = split(key, "_").at(1)
        const number = Number.parseInt(rightPart)
        if (isNaN(number)) {
          return this.$t(`company_system.price_periods.${key}`)
        } else {
          return this.$t(`company_system.price_periods.${this.timeSlotType}`, { number })
        }
      },

      updatePrice(key, value) {
        this.updatePriceRowPartial({ prices: { ...this.prices, [key]: value } })
      },

      selectCancellationFee({ id }) {
        this.updatePriceRowPartial({ cancellation_fee_id: id })
      },

      selectStandardOptions(options) {
        this.updatePriceRowPartial({ standard_option_ids: map(options, "id") })
      },

      selectSelectableOptions(options) {
        this.updatePriceRowPartial({ selectable_option_ids: map(options, "id") })
      },

      isStandardOptionSelected({ id }) {
        return get(this.priceRowModel, "standard_option_ids", []).includes(id)
      },

      isSelectableOptionSelected({ id }) {
        return get(this.priceRowModel, "selectable_option_ids", []).includes(id)
      },

      updatePriceRowPartial(changes) {
        this.$emit("change", { ...this.priceRowModel, ...changes })
      }
    },

    computed: {
      isEdit() {
        return !!this.priceRowModel.id
      },

      priceRowModel() {
        return this.priceRow.$model
      },

      prices() {
        return isEmpty(this.timeSlotType) ? {} : get(this.priceRowModel, "prices", {})
      },

      selectedCarClass() {
        return find(this.carClasses, ({ id }) => id === this.priceRowModel.car_class_id)
      },

      selectedCancellationFee() {
        return find(this.cancellationFees, ({ id }) => id === this.priceRowModel.cancellation_fee_id)
      },

      selectedStandardOptions() {
        return filter(this.standardOptions, option => this.isStandardOptionSelected(option))
      },

      selectedSelectableOptions() {
        return filter(this.selectableOptions, option => this.isSelectableOptionSelected(option))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"

  .car-class-settings-card
    width: 410px
    padding: 24px
    box-shadow: 2px 2px 4px -1px $default-gray-medium
    background-color: $default-white
    border-radius: 5px

    input
      &.is-invalid
        +default-invalid-input
    .is-invalid
      ::v-deep
        .dropdown-toggle
          +default-invalid-input

    .flex-row
      display: flex
      justify-content: left

    .form
      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &.actions
          flex-direction: row
          justify-content: space-between

          button
            min-width: 110px

          .delete
            border-color: $default-red
            ::v-deep
              svg
                path
                  fill: $default-red
              .title
                color: $default-red

          .clone
            border-color: $default-turquoise-medium
            ::v-deep
              svg
                path
                  fill: $default-turquoise-medium
              .title
                color: $default-turquoise-medium

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          &.price-list
            .price-list-item
              border: 1px solid $default-gray-light
              .period,
              .input
                width: 50%

              .period
                padding: 5px
                background-color: $default-gray-light

                .label
                  font-weight: 400
                  font-size: 13px
                  text-align: center
                  margin: 7px

              .input
                .currency
                  margin: 9px 5px

                ::v-deep
                  .app-number-input
                    width: 80%
                    margin: 5px auto

                    input
                      text-align: left
                      font-size: 16px
                      height: 32px !important
                      width: 100% !important
</style>
